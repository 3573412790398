@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat Black";
  src: url("./themes/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat ExtraBold";
  src: url("./themes/fonts/Montserrat/Montserrat-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./themes/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("./themes/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./themes/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("./themes/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("./themes/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("./themes/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

/* Proxima Nova */
@font-face {
  font-family: "Proxima Nova Alt Bold";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Alt-Bold.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Alt Light";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Alt-Light.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Alt Thin";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Alt-Thin.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Black";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Black.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Bold.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Extra Bold";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Extrabold.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Regular";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Regular.otf")
    format("opentype");
}

@font-face {
  font-family: "Proxima Nova Thin";
  src: url("./themes/fonts/Proxima-Nova/ProximaNova-Thin.otf")
    format("opentype");
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 768px) {
  .container {
    min-height: 400px;
    /* height: calc(100vh - 300px); */
  }
}

@media screen and (orientation: landscape) {
  .container {
    min-height: 400px;
    height: initial !important;
    margin-bottom: 20px;
  }
}

@media (max-height: 600px) {
  .container {
    min-height: 400px;
    /* height: calc(100vh - 300px); */
  }
}
